import React, {useEffect, useState} from "react";
import {getAuth, onAuthStateChanged, User} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {getStorage, ref, getDownloadURL} from "firebase/storage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
    Container,
    Typography,
    Button,
    Box,
    ThemeProvider,
    createTheme,
    CircularProgress,
    Grid,
} from "@mui/material";
import {doc, getDoc, getFirestore} from "firebase/firestore";

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#d1c4e9',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    padding: '12px 30px',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                        boxShadow: '0 6px 12px rgba(103, 58, 183, 0.3)',
                    },
                },
            },
        },
    },
});

function Technology() {
    const [name, setName] = useState("");
    const [urls, setUrls] = useState<string[]>([]);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [isLoadingUrls, setIsLoadingUrls] = useState(false);
    const [purchasedProducts, setPurchasedProducts] = useState<string[]>([]);

    const db = getFirestore();
    const auth = getAuth();
    const navigate = useNavigate();
    const storage = getStorage();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {

                if (user.displayName) {
                    setName(user.displayName);
                }
                await checkUserPurchases(user);
            } else {
                navigate("/login");
            }
            setIsLoadingUser(false);
        });
        return () => unsubscribe();
    }, []);

    async function checkUserPurchases(user: User) {
        try {
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                const data = userDocSnap.data();
                const purchases = data.purchasedProducts || [];
                setPurchasedProducts(purchases);
            } else {
                setPurchasedProducts([]);
            }
        } catch (error) {
            console.error("Error fetching doc:", error);
            setPurchasedProducts([]);
        }
    }

    useEffect(() => {
        setIsLoadingUrls(true);
        getUrls()
            .then(() => setIsLoadingUrls(false))
            .catch(() => setIsLoadingUrls(false));
    }, [purchasedProducts]);

    async function getUrls() {
        try {
            const downloadUrlC_light = await getDownloadURL(ref(storage, "CodeAPeel-C-V-6.zip"))
            const downloadUrlC = purchasedProducts.includes("CodeAPeel-C")
                ? await getDownloadURL(ref(storage, "CodeAPeel-C-V-6.zip"))
                : "";
            const downloadUrlH = purchasedProducts.includes("CodeAPeel-H") ? "download-url_H" : "";
            const downloadUrlM = purchasedProducts.includes("CodeAPeel-M") ? "download-url_M" : "";
            setUrls([downloadUrlC, downloadUrlH, downloadUrlM, downloadUrlC_light]);
        } catch (error) {
            console.error("Error fetching download URL:", error);
        }
    }

    if (isLoadingUser || isLoadingUrls) {
        return (
            <ThemeProvider theme={theme}>
                <Box
                    sx={{
                        minHeight: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: `
              radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%),
              radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%)`,
                        backgroundSize: 'cover',
                        backgroundAttachment: 'fixed',
                    }}
                >
                    <CircularProgress/>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    background: `
            radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%),
            radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%)`,
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                }}
            >
                <Navbar/>
                <Container
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        py: 4,
                    }}
                >
                    <Typography variant="h3" component="h1" gutterBottom color="primary">
                        Welcome {name}
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Below is a list of all the products you have purchased and are ready for download. When you
                        launch CodeAPeel, you will be prompted to enter your password—please use the same password you
                        use to log in to the CodeAPeel website. If your products do not appear immediately after
                        purchase, please refresh the page after a few minutes.
                    </Typography>
                    <Typography variant="body1" paragraph fontWeight="bold">
                        Important note:
                    </Typography>
                    <Typography variant="body2" paragraph>
                        You are allowed to copy and compile this program for personal use. However, no part of this
                        program may be reproduced, distributed, or stored in any form, including in a database or
                        retrieval system, without prior written permission from AlgoritiX Corporation. AlgoritiX makes
                        no claims, either direct or implied, regarding the program's accuracy, functionality, or
                        performance, nor does it guarantee the safety of the software on your device. By installing or
                        using this software, you agree to these terms.
                    </Typography>
                    <Grid container spacing={2} sx={{mt: 4}}>
                        {urls[0] && (
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    href={urls[0]}
                                    target="_blank"
                                    download="CodeAPeel-C"
                                    fullWidth
                                    sx={{
                                        color: '#ffffff',
                                        background: 'linear-gradient(90deg, #9c27b0 0%, #ba68c8 100%)',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '&:hover': {
                                            background: 'linear-gradient(90deg, #7b1fa2 0%, #9c27b0 100%)',
                                        },
                                        '&::after': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: 'rgba(255, 255, 255, 0.2)',
                                            transform: 'translateX(-100%)',
                                            transition: 'transform 0.3s ease',
                                        },
                                        '&:hover::after': {
                                            transform: 'translateX(100%)',
                                        },
                                    }}
                                >
                                    Download CodeAPeel-C
                                </Button>
                            </Grid>
                        )}
                        {urls[1] && (
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    href={urls[1]}
                                    target="_blank"
                                    download="CodeAPeel-H"
                                    fullWidth
                                    sx={{
                                        color: '#ffffff',
                                        background: 'linear-gradient(90deg, #4caf50 0%, #81c784 100%)',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '&:hover': {
                                            background: 'linear-gradient(90deg, #388e3c 0%, #4caf50 100%)',
                                        },
                                        '&::after': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: 'rgba(255, 255, 255, 0.2)',
                                            transform: 'translateX(-100%)',
                                            transition: 'transform 0.3s ease',
                                        },
                                        '&:hover::after': {
                                            transform: 'translateX(100%)',
                                        },
                                    }}
                                >
                                    Download CodeAPeel-H
                                </Button>
                            </Grid>
                        )}
                        {urls[2] && (
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    href={urls[2]}
                                    target="_blank"
                                    download="CodeAPeel-M"
                                    fullWidth
                                    sx={{
                                        color: '#ffffff',
                                        background: 'linear-gradient(90deg, #ff9800 0%, #ffb74d 100%)',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '&:hover': {
                                            background: 'linear-gradient(90deg, #f57c00 0%, #ff9800 100%)',
                                        },
                                        '&::after': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: 'rgba(255, 255, 255, 0.2)',
                                            transform: 'translateX(-100%)',
                                            transition: 'transform 0.3s ease',
                                        },
                                        '&:hover::after': {
                                            transform: 'translateX(100%)',
                                        },
                                    }}
                                >
                                    Download CodeAPeel-M
                                </Button>
                            </Grid>
                        )}
                        {urls[3] && !urls[0] && (
                            <Grid item xs={12} sm={4}>
                                <Button
                                    variant="contained"
                                    href={urls[3]}
                                    target="_blank"
                                    download="CodeAPeel-C_light"
                                    fullWidth
                                    sx={{
                                        color: '#ffffff',
                                        background: 'linear-gradient(90deg, #b67ad6 50%, #e1bee7 100%)',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        '&:hover': {
                                            background: 'linear-gradient(90deg, #ba68c8 0%, #ce93d8 100%)',
                                        },
                                        '&::after': {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            background: 'rgba(255, 255, 255, 0.2)',
                                            transform: 'translateX(-100%)',
                                            transition: 'transform 0.3s ease',
                                        },
                                        '&:hover::after': {
                                            transform: 'translateX(100%)',
                                        },
                                    }}
                                >
                                    Download CodeAPeel-C Light
                                </Button>
                            </Grid>
                        )}
                    </Grid>

                </Container>
                <Footer/>
            </Box>
        </ThemeProvider>
    );
}

export default Technology;
